import isTouchDevice from "is-touch-device";

const images = document.getElementById("images__container");
const mediaSection = document.getElementById("media");
const video = document.getElementById("video");
const isTouch = isTouchDevice();

// Variables
let timeout = null;
let activeIndex = 0;

// Functions
const onMouseEnter = () => {
  if (isTouch) return;
  clearTimeout(timeout);
  timeout = null;
};
const onMouseLeave = () => {
  if (isTouch) return;
  animateImages(activeIndex);
};
const onTouchStart = (e) => {
  e.preventDefault();
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
};
const onTouchEnd = (e) => {
  if (e.touches.length === 0) {
    animateImages(activeIndex);
  }
};
const onMouseEnterVideo = () => {
  if (isTouch) return;
  video.pause();
};
const onMouseLeaveVideo = () => {
  if (isTouch) return;
  video.play();
};
const onTouchStartVideo = (e) => {
  e.preventDefault();
  video.pause();
};
const onTouchEndVideo = (e) => {
  if (e.touches.length === 0) {
    video.play();
  }
};

const animateImages = (i) => {
  activeIndex = i;
  images.children[i].style.opacity = 1;
  const nextIndex = i === images.children.length - 1 ? 0 : i + 1;
  timeout = setTimeout(() => {
    images.children[i].style.opacity = 0;
    animateImages(nextIndex);
  }, 150);
};

// Event Listeners
if (images) {
  images.addEventListener("mouseenter", onMouseEnter);
  images.addEventListener("mouseleave", onMouseLeave);
  images.addEventListener("touchstart", onTouchStart);
  images.addEventListener("touchend", onTouchEnd);
  animateImages(0);
}

if (video) {
  video.addEventListener("mouseenter", onMouseEnterVideo);
  video.addEventListener("mouseleave", onMouseLeaveVideo);
  video.addEventListener("touchstart", onTouchStartVideo);
  video.addEventListener("touchend", onTouchEndVideo);
}

mediaSection.style.opacity = 1;
